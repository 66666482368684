<template>
  <el-container style="background:#F8F8F8">
    <el-header>
      <div class="header-left">
        <img src="../../assets/images/home_img_Logo_nor.png" alt="" />
        <span>高品质出行 有我有礼</span>
      </div>
      <ul class="clearfix">
        <li>
          <a href="javascript:;" @click="changeIndex(0)"
            >首页 <span class="line" v-if="activeIndex == 0"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(1)"
            >预约出行 <span class="line" v-if="activeIndex == 1"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(2)"
            >英伦座驾 <span class="line" v-if="activeIndex == 2"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(3)"
            >品质服务 <span class="line" v-if="activeIndex == 3"></span
          ></a>
        </li>
        <li>
          <a href="javascript:;" @click="changeIndex(4)"
            >百年经典 <span class="line" v-if="activeIndex == 4"></span
          ></a>
        </li>
        <!-- <li>
          <a href="javascript:;" @click="changeIndex(6)"
            >政企服务 <span class="line" v-if="activeIndex == 6"></span
          ></a>
        </li> -->
        <li>
          <a href="javascript:;" @click="changeIndex(5)"
            >司机招募 <span class="line" v-if="activeIndex == 5"></span
          ></a>
        </li>
      </ul>
    </el-header>
    <el-main>
      <section class="connect">
        <h2>联系我们</h2>
        <p><span class="label">客服热线：</span>95129</p>
        <p><span class="label">公司地址：</span>浙江省杭州市滨江区西兴街道江陵路1782</p>

      </section>
      <Footer />
    </el-main>
  </el-container>
</template>

<script>
import { mapState } from "vuex";
import Footer from "./footer.vue";
export default {
  components: {
    Footer,
  },
  computed: {
    ...mapState({
      homeIndex: (store) => store.common.homeIndex,
    }),
  },
  mounted() {
    this.$store.commit("loading/HIDELOADING");
  },
  methods: {
    changeIndex(index) {
      this.$store.commit("common/HOMEINDEX", index);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped lang="scss">
.connect {
    margin: 120px auto 60px;
    padding: 40px 60px 60px;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    color: rgba(0, 0, 0, 0.9);
    line-height: 28px;
    background: #fff;
    display: inline-block;
}
.connect h2 {
    position: relative;
    width: 100%;
    font-size: 40px;
    font-weight: 500;
    color: #222222;
    line-height: 44px;
    text-align: center;
    margin: 0 0 30px;
    display: inline-block;
    &::after{
        position: absolute;
        left: 50%;
        bottom: -10px;
        content: '';
        width: 160px;
        margin-left: -80px;
        height: 3px;
        background: #FFEC00;
    }
}
.connect p {
    margin-top: 30px;
    .label{
        color: rgba(0, 0, 0, 0.6);
    }
}


.el-container {
  background: #fff;
  // height: 100vh;
  color: #222;
  .el-header {
    padding: 15px 60px;
    position: fixed;
    top: 0;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin: 0px auto;
    background: #ffffff;
    font-size: 14px;
    min-width: 1200px;
    height: 60px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    z-index: 999;
    .header-left {
      img {
        display: inline-block;
        height: 30px;
        vertical-align: middle;
        display: inline-block;
        padding-right: 10px;
      }
      span {
        vertical-align: middle;
        border-left: 1px solid #e6e6e6;
        display: inline-block;
        padding-left: 10px;
        font-family: PingFangSC-Light;
        border-radius: 1px;
      }
    }
    ul {
      li {
        float: left;
        a {
          margin-left: 70px;
          display: inline-block;
          line-height: 30px;
          font-family: PingFangSC-Regular;
          position: relative;
          height: 32.5px;
        }
        a:after {
          content: " ";
          position: absolute;
          z-index: 2;
          bottom: 0px;
          left: 50%;
          display: block;
          width: 18px;
          height: 1px;
          transform: translate(-50%);
        }
        a:hover:after {
          height: 2px;
          animation: ad_width 0.1s linear forwards;
          background: #181818;
        }
        .line {
          box-sizing: border-box;
          line-height: 10px;
          display: block;
          width: 18px;
          height: 2.5px;
          border: 1px solid #181818;
          border-radius: 20%;
          background: #181818;
          margin: 0 auto;
        }
        @keyframes ad_width {
          from {
            width: 0;
          }

          to {
            width: 18px;
          }
        }
      }
    }
  }
  .el-main {
    // height: calc(100vh - 60px);
    overflow-x: hidden;
    padding: 0;
    // margin-top: 60px;
    width: 100%;
    #infomation7 {
      height: 890px;
      margin-top: 60px;
      background: url("../../assets/images/server-home.png") no-repeat;
      background-size: cover;
      position: relative;
    }
    #infomation7 .server-box {
      position: absolute;
      right: 160px;
      top: 253px;
      width: 420px;
      height: 384px;
      background-color: rgba(0, 0, 0, 0.5);
      backdrop-filter: blur(20px);
      text-align: center;
      overflow: hidden;
      padding: 48px 60px 30px;
      box-sizing: border-box;
    }

    #infomation7 .server-box .title {
      font-size: 24px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 28px;
      padding-bottom: 0;
    }
    ::v-deep .server-box {
      .el-input {
        margin-top: 20px;
        .el-input__inner {
          color: #fff;
          border-radius: 0;
          border-top: 0;
          border-left: 0;
          border-right: 0;
          background-color: inherit;
          text-align: center !important;
        }
      }
    }
    #infomation7 .button-box {
      .el-button {
        margin-top: 50px;
        width: 100%;
        background: #f9d230;
        border-radius: 6px;
        border-color: #f9d230;
        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #17191e;
      }
    }
    .server-btitle {
      padding-top: 100px;
      text-align: center;
      font-size: 40px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 44px;
    }

    .server-stitle {
      padding-top: 24px;
      font-size: 20px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #222222;
      line-height: 24px;
    }
    .server-flex-box {
      padding: 71px 179px 124px;
      margin-left: -40px;
      margin-right: -40px;
      width: calc(100% + 80px);
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(50% - 40px);
        .img-box {
          // height: 452px;
          img {
            width: 100%;
            height: 100%;
            display: inline-block;
          }
        }
        .flex-child-title {
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
          text-align: left;
          padding-top: 23px;
        }
      }
    }
    .yl-box {
      background-color: #f8f8f8;
    }
    .server-flex-yl {
      padding: 71px 179px 124px;
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .flex-child {
        width: calc(33% - 30px);
        .img-box {
          // height: 430px;
          img {
            width: 100%;
            // height: 430px;
            display: inline-block;
          }
        }
        .yl-child-title {
          padding-top: 23px;
          text-align: left;
          font-size: 24px;
          font-family: PingFang-SC-Medium, PingFang-SC;
          font-weight: 500;
          color: #222222;
          line-height: 28px;
        }
        .yl-child-title2 {
          padding-top: 16px;
          text-align: left;
          font-size: 16px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          color: #222222;
          line-height: 20px;
        }
      }
    }

    .first-box,
    .six-box {
      width: 100%;
      img {
        display: inline-block;
        width: 100%;
      }
    }
    .first-box {
      .kong {
        height: 60px;
      }
    }
    .forth-box {
      font-family: PingFangSC-Light;
      height: 890px;
      background: url("../../assets/images/bg.png") no-repeat;
      background-size: cover;
      .name {
        font-size: 40px;
        color: #222222;
        padding: 100px 0 120px 0;
      }
      .rel-box {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        img {
          display: inline-block;
          width: 873px;
          height: 440px;
        }
        span {
          font-family: PingFangSC-Regular;
          color: #222;
        }
        .sp1 {
          position: absolute;
          top: 0;
          left: 40px;
        }
        .sp2 {
          position: absolute;
          top: -30px;
          left: 265px;
        }
        .sp3 {
          position: absolute;
          bottom: 152px;
          left: -135px;
        }
        .sp4 {
          position: absolute;
          bottom: -25px;
          left: 187px;
        }
      }
    }
    .second {
      background: #f8f8f8;
      padding: 95px 60px;
      min-width: 1200px;
    }

    .second-box {
      margin: 0 auto;
      .img-box {
        width: 46%;
        img {
          display: inline-block;
          width: 100%;
          //   height: 700px;
        }
      }
      .logo-box {
        padding-left: 60px;
        width: 54%;
        .info-box1 {
          font-family: PingFangSC-Regular;
          margin-top: 60px;
          font-size: 24px;
          color: #222222;
          text-align: left;
          line-height: 50px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
        }
        @media only screen and (min-width: 1281px) {
          .info-box1 {
            font-size: 32px;
          }
        }
        .info-box2 {
          font-size: 12px;
          color: #222222;
          text-align: left;
          line-height: 30px;
          margin-top: 40px;
          margin-bottom: 40px;
          // text-overflow: -o-ellipsis-lastline;
          // overflow: hidden;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-line-clamp: 2;
          // line-clamp: 2;
          // -webkit-box-orient: vertical;
          font-family: PingFangSC-Regular;
        }
        @media only screen and (min-width: 1281px) {
          .info-box2 {
            font-size: 18px;
          }
        }
        .img-box2 {
          text-align: center;
          img {
            display: inline-block;
            width: 240px;
            height: 220px;
            //   height: 200px;
          }
        }
        @media only screen and (min-width: 1440px) {
          img {
            margin-top: 0px;
          }
        }
        @media only screen and (min-width: 1680px) {
          img {
            margin-top: 70px;
          }
        }
      }
    }

    .third-box {
      background: #ffffff;

      .title {
        font-family: PingFangSC-Light;
        padding: 100px 0 120px 0;
        text-align: center;
        color: #222222;
        font-size: 40px;
      }
    }
    .five-box {
      background: #f8f8f8;
      padding-bottom: 120px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 44px;
        padding-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .img-box {
        display: flex;
        padding: 0 190px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        .five-img-box {
          width: calc(33% - 30px);
          margin: 15px;
          .simg-box {
            width: 100%;
            img {
              display: inline-block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 24px;
            margin-top: 24px;
            font-family: PingFang-SC-Medium;
          }
          .stitle {
            font-family: PingFangSC-Light;
            text-align: left;
            font-size: 16px;
            margin-top: 16px;
          }
        }
      }
    }
    .swiper-box {
      display: flex;
      padding: 0 190px 120px 190px;
      justify-content: space-between;
    }
    .swiper-container {
      margin-left: -15px;
      margin-right: -15px;
      width: calc(100% + 30px);
      transform: translate3d(0, 0, 0);
      overflow: hidden;
      .banner-swiper-item {
        transform: translate3d(0, 0, 0);
        border-radius: 4px;
        // width: 100%;
        width: calc(33% - 30px);
        height: 100%;
        overflow: hidden;
        display: block;
        position: relative;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
        .swiper-info {
          text-align: left;
          font-size: 24px;
          color: #222222;
          font-family: PingFang-SC-Medium;
          margin-top: 24px;
        }
        .swiper-sinfo {
          text-align: left;
          font-size: 16px;
          color: #222222;
          font-family: PingFangSC-Light;
          margin-top: 16px;
          line-height: 16px;
        }
      }
    }
    .seven-box {
      padding-top: 100px;
      background: #fff;
      padding-bottom: 139px;
      .info {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 40px;
        // margin-top: 100px;
      }
      .sinfo {
        font-family: PingFangSC-Light;
        text-align: center;
        color: #222222;
        font-size: 20px;
        margin-top: 24px;
        margin-bottom: 72px;
      }
      .seven-img-box {
        display: flex;
        padding: 0 60px;
        justify-content: space-between;
        margin-left: -15px;
        margin-right: -15px;
        width: calc(100% + 30px);
        min-width: 1200px;
        .img-box {
          width: calc(25% - 30px);
          margin: 15px;
          background: #f1f1f1;
          .simg-box {
            width: 100%;
            img {
              display: inline-block;
              width: 100%;
            }
          }
          .title {
            text-align: left;
            font-size: 18px;
            margin-top: 10px;
            padding-left: 13px;
            // padding-bottom: 13px;
            font-family: PingFangSC-Regular;
          }
          .stitle {
            padding: 0 13px 13px 13px;
            text-align: left;
            font-size: 14px;
            margin-top: 8px;
            line-height: 16px;
            font-family: PingFangSC-Light;
          }
        }
      }
    }
    .nine-box {
      padding-top: 100px;
    }
    .manager-box {
      width: 52%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // padding-left: 117px;
      .title {
        font-family: PingFangSC-Light;
        padding-bottom: 100px;
        font-size: 40px;
        color: #222222;
      }
      .input-box {
        // margin-left: 93px;
        // width: 360px;
        margin-bottom: 30px;
        .el-input {
          width: 360px;
          .el-input__inner {
            height: 50px;
            width: 360px;
            font-family: PingFang-SC-Medium;
          }
        }
      }
      .button-box {
        padding-bottom: 136px;
        .el-button {
          background: #181818;
          width: 160px;
          border-color: #181818;
        }
      }
    }
    .eight-box {
      display: flex;
      min-width: 1200px;
      .left-box {
        width: 48%;
        img {
          display: inline-block;
          width: 100%;
          height: 100%;
        }
      }
      .right-box {
        width: 52%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        // padding-left: 117px;
        background: #f8f8f8;
        padding-top: 60px;
        .title {
          font-family: PingFangSC-Light;
          padding-bottom: 100px;
          font-size: 40px;
          color: #222222;
        }
        .input-box {
          // margin-left: 93px;
          // width: 360px;
          margin-bottom: 30px;
          .el-input {
            width: 360px;
            .el-input__inner {
              height: 50px;
              width: 360px;
              font-family: PingFang-SC-Medium;
            }
          }
        }
        .button-box {
          padding-bottom: 136px;
          .el-button {
            background: #181818;
            width: 160px;
            border-color: #181818;
          }
        }
      }
    }

    .footer {
      font-size: 14px;
      margin-top: -4px;
      background: #333;
      padding: 55px 100px 0 100px;
      min-width: 1200px;
      color: #fff;
      .pull-left {
        padding-right: 144px;
      }
      .name {
        text-align: left;
        font-size: 16px;
        margin-bottom: 30px;
        font-family: PingFangSC-Regular;
      }
      ul {
        padding-bottom: 20px;
        li {
          text-align: left;
          line-height: 18px;
          padding-bottom: 12px;
          a {
            color: #fff;
            font-family: PingFangSC-Light;
          }
          a:hover {
            color: #f9d230;
          }
        }
      }
      .box1 {
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      }
      .box2 {
        padding: 30px 0;
        font-size: 16px;
        font-family: PingFangSC-Light;
        a {
          color: #fff;
        }
      }
    }
  }
}
</style>
